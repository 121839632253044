'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _plugin = require('@swup/plugin');

var _plugin2 = _interopRequireDefault(_plugin);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SwupMatomoPlugin = function (_Plugin) {
	_inherits(SwupMatomoPlugin, _Plugin);

	function SwupMatomoPlugin() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, SwupMatomoPlugin);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SwupMatomoPlugin.__proto__ || Object.getPrototypeOf(SwupMatomoPlugin)).call.apply(_ref, [this].concat(args))), _this), _this.name = 'SwupMatomoPlugin', _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(SwupMatomoPlugin, [{
		key: 'mount',
		value: function mount() {
			var _this2 = this;

			this.swup.on('contentReplaced', function (event) {
				if (typeof window._paq !== 'undefined') {
					var title = document.title;
					var url = window.location.pathname + window.location.search;

					_paq.push(['setDocumentTitle', title]);
					_paq.push(['setCustomUrl', url]);
					_paq.push(['trackPageView']);

					_this2.swup.log('Matomo pageview (url \'' + url + '\').');
				} else {
					console.warn('Matomo is not loaded.');
				}
			});
		}
	}]);

	return SwupMatomoPlugin;
}(_plugin2.default);

exports.default = SwupMatomoPlugin;