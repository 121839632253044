import Swup from 'swup'
import SwupMatomoPlugin from '@swup/matomo-plugin'
import SwupPreloadPlugin from '@swup/preload-plugin'
import Vision from '../page/Vision'
import Design from '../page/Design'
import Work from '../page/Work'
import Studio from '../page/Studio'
import Contact from '../page/Contact'
import Page from '../page/Page'
import Home from '../page/Home'
import Article from '../page/Article'

const LINK_SELECTOR = 'a[href^="' + window.location.origin + '"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])'

export default class Navigation {

    constructor(_menu, _scroll, _device, _dictionary) {
        this.scroll = _scroll
        this.device = _device
        this.menu = _menu
        this.dictionary = _dictionary
        this.dictionary.setOnLoadAction(_ => {
            this.cleanLinks()
            this.setLinks()
        })
        this.swup = new Swup({
            containers: ["main"],
            linkSelector: 'a.direct-swup',
            plugins: [
                new SwupPreloadPlugin(),
                new SwupMatomoPlugin()
            ]
        })
        this.page = null
        this.setCurrentPage()
        this.swup.on('samePage', _ => this.menu.close())
        this.swup.on('transitionStart', _ => {
            this.menu.close()
            _scroll.unload()
            if (this.page) {
                this.page.kill()
                this.page = null
            }
        })
        this.swup.on('contentReplaced', _ => {
            this.scroll.smoothScrollResizeAction()
            this.setCurrentPage()
        })
    }

    setCurrentPage() {
        const path = window.location.pathname.split('/')
        this.id = path[1] != "" ? path[1] : "home"
        document.body.setAttribute('id', this.id)
        this.menu.nowFocusOn(this.id)
        this.setLinks()
        // Load page script
        switch (this.id) {
            case "home":
                this.page = new Home(this.scroll, this.device)
                break
            case "work":
                this.page = new Work(this.scroll, this.device)
                break
            case "article":
                this.page = new Article(this.scroll, this.device)
                break
            case "vision":
                this.page = new Vision(this.scroll, this.device)
                break
            case "design":
                this.page = new Design(this.scroll, this.device)
                break
            case "studio":
                this.page = new Studio(this.scroll, this.device)
                if (this.scrollToEvents) {
                    this.scroll.goToEventsSection()
                    this.scrollToEvents = false
                }
                break
            case "contact":
                this.page = new Contact(this.scroll, this.device)
                break
            default:
                this.page = new Page(this.scroll, this.device)
                console.warn("Unknown page id : ", this.id);
                break
        }
        this.page.mainLoop()
        // console.log('start loop')
    }

    cleanLinks() {
        this.links.forEach( _link => {
            if (this.linkHandlers == null) return
            let linkHandler = this.linkHandlers.get(_link)
            let handler = linkHandler.handler ?? null
            _link.removeEventListener('click', handler, false)
        })
        this.linkHandlers = null
        this.links = null
    }

    setLinks() {
        this.linkHandlers = new WeakMap()
        this.links = document.querySelectorAll(LINK_SELECTOR)
        this.links.forEach( _link => {
            this.linkHandlers.set(_link, { handler: _e => this.linkClick(_e, _link) });
            var handler = this.linkHandlers.get(_link).handler
            _link.addEventListener('click', handler, false)
        })
    }

    linkClick(_e, _link) {
        _e.preventDefault()
        let url = _link.getAttribute('href')
        console.log(url)
        switch (url) {
            case '#footer-newsletter':
                document.querySelector('#js-footerNewsletterForm').classList.add('visible')
                return
            case '#menu':
                this.menu.toggle()
                return
            case '#close-dictionary':
                this.dictionary.close(_e)
                return
            case '/studio#events':
                this.scrollToEvents = true
                url = "/studio"
            default:
                if (url.startsWith('/definition/') && this.id != 'dictionary' && this.id != 'definition') {
                    this.dictionary.load(url)
                    return
                }
                this.menu.nowFocusOn(url)
                this.swup.preloadPage(url)
                const delay = this.launchTransition(url, _e)
                this.cleanLinks()
                setTimeout(_ => {
                    this.dictionary.close(null)
                    this.swup.loadPage({url})
                }, delay)
                break
        }
    }

    launchTransition(_dest, _e) {
        if (_dest.startsWith('/work/')) {
            this.transitionToWork(_e)
            document.documentElement.classList.add('is-animating-to-work')
            return 500
        } else if (_dest.startsWith('/article/')) {
            this.transitionToArticle(_e)
            document.documentElement.classList.add('is-animating-to-article')
            return 500
        } else {
            document.documentElement.classList.add('is-animating')
            return 600
        }
    }

    /*
    Custom Transitions
    */

    setCustomTransitionPanel(color) {
        const panelOverlay = document.createElement('div')
        panelOverlay.classList.add('transi-screen')
        panelOverlay.style.background = color ?? '#fff'
        document.body.appendChild(panelOverlay)
        panelOverlay.getBoundingClientRect() // Force reflow
    }

    transitionToWork(_e) {
        const workDiv = _e.currentTarget
        const img = workDiv.querySelector('.preview')
        const boundingRect = img.getBoundingClientRect()
        const overlayImg = img.cloneNode()
        overlayImg.setAttribute('id', 'transition-work-image')
        overlayImg.style.top = `${boundingRect.top}px`
        overlayImg.style.left = `${boundingRect.left}px`
        overlayImg.style.width = `${boundingRect.width}px`
        document.body.appendChild(overlayImg)
        this.setCustomTransitionPanel(workDiv.getAttribute('data-color'))
    }

    transitionToArticle(_e) {
        const article = _e.currentTarget
        const boundingRect = article.getBoundingClientRect()
        const overlayArticle = article.cloneNode(true)
        overlayArticle.setAttribute('id', 'transition-article-preview')
        overlayArticle.style.top = `${boundingRect.top}px`
        overlayArticle.style.left = `${boundingRect.left}px`
        overlayArticle.style.width = `${boundingRect.width}px`
        document.body.appendChild(overlayArticle)
        // this.setCustomTransitionPanel(article.getAttribute('data-color'))
        this.setCustomTransitionPanel("#1F1F1F")
        // Launch animation
        setTimeout(() => {
            const x = ((this.device.screen.width / 2) - (boundingRect.width / 2)) - boundingRect.left
            const y = ((this.device.screen.height / 2) - (boundingRect.height / 2)) - boundingRect.top
            overlayArticle.style.transform = `translateX(${x}px) translateY(${y}px)`
        }, 350)
    }

}