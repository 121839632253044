import FileInputBox from "../class/FileInputBox"
import ImageScrollBand from "../class/ImageScrollBand"
import InputBox from "../class/InputBox"
import NewsletterForm from "../class/NewsletterForm"

export default class Page {

    constructor(_scroll, _device) {
        this.scroll = _scroll
        this.device = _device
        this.isAlive = true
        this.animatedShapes = document.querySelectorAll('.js-animated-shape') ?? []
        this.animatedShapes.forEach(shape => {
            shape.speed = shape.getAttribute('data-speed')
            shape.max = -parseInt(shape.getAttribute('data-max') ?? 100)
            shape.endOpacity = parseFloat(shape.getAttribute('data-opacity') ?? 1)
        })
        // Manage dark-background
        this.darkBackgrounds = document.querySelectorAll('.dark-bg:not(#dictionary-modal)')
        // Manage inputs
        this.inputBoxes = {}
        document.querySelectorAll('.input-box').forEach(_elem => {
            const inputElem = _elem.querySelector('input')
            const input = this.generateInput(_elem, inputElem)
            this.inputBoxes[inputElem.getAttribute('id')] = input
        })
        // Newsletter forms
        this.newsletterForms = []
        document.querySelectorAll('.js-newsletterForm').forEach( (_form, _i) => {
            const inputId = _form.querySelector('input').getAttribute('id')
            this.newsletterForms.push(new NewsletterForm(_form, this.inputBoxes[inputId], _i))
        })
        // Our thinking slider
        this.thinking = document.querySelector('#js-our-thinking')
        if (this.thinking) {
            this.thinking = new ImageScrollBand(this.thinking, null, false)
        }
        // Page resize
        this.pageResizeAction = _ => {
            this.darkBackgrounds.forEach(bg => {
                const rect = bg.getBoundingClientRect()
                bg.data = {
                    yMin: rect.top + this.scroll.data.y,
                    yMax: rect.top + this.scroll.data.y + rect.height
                }
            })
            this.animatedShapes.forEach(shape => {
                const rect = shape.getBoundingClientRect()
                shape.data = {
                    y: rect.top + this.scroll.data.y,
                    height: rect.height
                }
            })
        }
        this.pageResizeAction()
        window.addEventListener('resize', this.pageResizeAction)
    }

    generateInput(_elem, _input) {
        switch (_input.getAttribute('type')) {
            case 'file': return new FileInputBox(_elem)
            default: return new InputBox(_elem)
        }
    }

    kill() {
        this.isAlive = false
        this.newsletterForms.forEach(_form => _form.kill())
        window.removeEventListener('resize', this.pageResizeAction)
        if (this.thinking) this.thinking.kill()
    }

    setDarkBackground(_isDark) {
        if (_isDark) {
            document.body.classList.add('backgroundIsDark')
            if (this.scroll.data.y == 0) {
                document.body.classList.add('forceWhiteContact')
            } else {
                document.body.classList.remove('forceWhiteContact')
            }
        } else {
            document.body.classList.remove('backgroundIsDark', 'forceWhiteContact')
        }
    }

    mainLoop() {
        if (this.isAlive) {
            requestAnimationFrame(_ => this.mainLoop())
        }
        let isBackgroundDark = false
        for (let i = 0; i < this.darkBackgrounds.length; i++) {
            const bg = this.darkBackgrounds[i]
            if (bg.data.yMin > this.scroll.data.y + 64)
                break
            if (bg.data.yMax >= this.scroll.data.y + 64) {
                isBackgroundDark = true
                break
            }
        }
        this.setDarkBackground(isBackgroundDark)
        this.animatedShapes.forEach(shape => {
            const d = shape.data.y - this.scroll.data.y
            const f = Math.max(shape.max, d / this.device.screen.height)
            const y = f * shape.speed
            shape.style.transform = `translateY(${y}vh)`
            const fCorrected = (f + y/100)
            shape.style.opacity = Math.min(shape.endOpacity, 2 * (1 - (fCorrected * shape.endOpacity)) )
            if (shape.lottie) {
                shape.lottie.update(fCorrected)
            }
        })
        this.loop()
    }

    loop() {

    }

}