import Page from "./Page"
import LottiePlayer from "../class/LottiePlayer"
import ImageScrollBand from "../class/ImageScrollBand"

export default class Home extends Page {

    constructor(_scroll, _device) {
        super(_scroll, _device)
        this.heroVideo = document.querySelector('#js-hero-video')
        this.videoReadyAction = _ => {
            this.heroVideo.classList.add('loaded')
        }
        this.heroVideo.addEventListener('canplay', this.videoReadyAction)
        this.animatedShapes.item(0).lottie = new LottiePlayer('lottie-home-m', 'p1_m', _scroll)
        this.animatedShapes.item(2).lottie = new LottiePlayer('lottie-home-curved-diamond', 'p1_curved_diamond', _scroll)
        this.animatedShapes.item(4).lottie = new LottiePlayer('lottie-home-triangle', 'p1_triangle', _scroll)
        this.echos = new ImageScrollBand(document.querySelector('#js-our-echos'), 700, false)
        this.resizeAction = _ => {
            const ratio = window.device.screen.width / window.device.screen.height
            if (window.device.screen.width < 700 && ratio < 0.9) {
                this.setVideo(true)
            } else {
                this.setVideo(false)
            }
        }
        this.resizeAction()
        window.addEventListener('resize', this.resizeAction)
    }

    setVideo(toMobile) {
        const dest = toMobile ? this.heroVideo.getAttribute('data-mobile-video') : this.heroVideo.getAttribute('data-default-video')
        const current = this.heroVideo.getAttribute('src')
        if (current == dest) return
        this.heroVideo.setAttribute('src', dest)
    }

    kill() {
        super.kill()
        this.animatedShapes.item(0).lottie.kill()
        this.animatedShapes.item(2).lottie.kill()
        this.animatedShapes.item(4).lottie.kill()
        this.heroVideo.removeEventListener('canplay', this.videoReadyAction)
        window.removeEventListener('resize', this.resizeAction)
    }

}