export default class Dictionary {

    constructor() {
        this.modal = document.querySelector('#dictionary-modal')
        this.container = this.modal.querySelector('#dictionary-modal-content')
        this.background = document.querySelector('#dictionary-modal-background')
        this.background.addEventListener('click', _ => this.close())
        this.onLoad = _ => {}
    }

    setOnLoadAction(_onLoad) {
        this.onLoad = _onLoad
    }

    close(_e) {
        if (_e) {
            _e.preventDefault()
        }
        document.body.classList.remove('dicoOpen')
        setTimeout(() => {
            this.modal.scrollTo(0, 0)
            document.body.classList.remove('dicoLoaded')
        }, 500)
    }

    load(url) {
        const id = url.split('/')[2]
        const contentUrl = `/content/definition.php?id=${id}`
        let end = Date.now()
        if (document.body.classList.contains('dicoOpen')) {
            document.body.classList.remove('dicoLoaded')
            end += 300
        } else {
            document.body.classList.add('dicoOpen')
        }
        fetch(contentUrl)
            .then(data => data.text())
            .then(html => {
                this.container.innerHTML = html
                this.onLoad()
                setTimeout(() => {
                    this.modal.scrollTo(0, 0)
                    document.body.classList.add('dicoLoaded')
                }, end - Date.now())
            })
            .catch(error => {
                console.log('Load fail : ', error)
            })
    }

}