import DeviceData from './class/DeviceData'
import SmoothScroll from './class/SmoothScroll'
import Navigation from './class/Navigation'
import Menu from './class/Menu'
import Dictionary from './class/Dictionary'

window.device = new DeviceData()
const scroll = new SmoothScroll(window.device)
const menu = new Menu(scroll)
const dictionary = new Dictionary()
const nav = new Navigation(menu, scroll, window.device, dictionary)