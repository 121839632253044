import { checkEmail } from "../Helpers"

export default class NewsletterForm {

    constructor(_form, _input, _index) {
        this.form = _form
        this.input = _input
        this.index = _index
        this.submitAction = _e => this.submit(_e)
        this.form.addEventListener('submit', this.submitAction)
        this.resetAction = _e => this.reset(_e)
        this.form.addEventListener('reset', this.resetAction)
    }

    setCustomHandler(_handler) {
        this.handler = _handler
    }

    kill() {
        this.form.removeEventListener('submit', this.submitAction)
        this.form.removeEventListener('reset', this.resetAction)
    }

    submit(_e) {
        _e.preventDefault()
        let data = new FormData(this.form)
        if (data.get('email').length <= 0 || !checkEmail(data.get('email'))) {
            this.input.setErrorMessage('Tell us your email')
            return
        }
        this.form.classList.add('loading')
        const id = "NewsletterFrom-" + (document.body.getAttribute('id') ?? "unknown") + "-" + this.index
        _paq.push(['trackEvent', 'NewsletterSubscription', id])
        fetch(this.form.getAttribute('action'), { method: 'POST', body: data })
            .then(res => res.json())
            .then(json => {
                if (json.success) {
                    if (this.handler) {
                        this.handler()
                    } else {
                        this.form.classList.add('success')
                        setTimeout(() => {
                            this.form.classList.remove('visible')
                        }, 2000)
                        setTimeout(() => {
                            this.input.input.value = ''
                            this.form.classList.remove('loading', 'success')
                        }, 3000)
                    }
                } else {
                    console.warn(json)
                    window.alert(`Une erreur est survenue : ${json.error}. Merci, de nous contactez par mail à hello@meaningful.fr.`)
                }
            })
            .catch(err => {
                console.warn(err)
                window.alert(`Une erreur est survenue : ${err}. Merci, de nous contactez par mail à hello@meaningful.fr.`)
            })
    }

    reset() {
        let footerForm = document.querySelector('#js-footerNewsletterForm')
        if (footerForm) {
            footerForm.classList.remove('visible')
        }
    }

}