import Page from "./Page"
import ImageScrollBand from "../class/ImageScrollBand"
import LottiePlayer from "../class/LottiePlayer"

export default class Studio extends Page {

    constructor(_scroll, _device) {
        super(_scroll, _device)
        this.journey = document.querySelector('#js-journey')
        this.journeyContent = this.journey.querySelector('#js-journey-content')
        this.animatedShapes.item(0).lottie = new LottiePlayer('lottie-studio-curved-diamond', 'p4_curved_diamond', _scroll)
        this.animatedShapes.item(1).lottie = new LottiePlayer('lottie-studio-m', 'p1_m', _scroll)
        this.animatedShapes.item(3).lottie = new LottiePlayer('lottie-studio-bubbled-diamond', 'p4_bubbled_diamond', _scroll)
        this.resizeAction = _ => {
            this.journey.data = {
                y: this.journey.getBoundingClientRect().top + this.scroll.data.y,
                height: this.journey.offsetHeight
            }
        }
        this.imageScrollBand = new ImageScrollBand(this.journey.querySelector('#js-displays'), 700, false)
        this.resizeAction()
        window.addEventListener('resize', this.resizeAction)
    }

    kill() {
        super.kill()
        this.animatedShapes.item(0).lottie.kill()
        this.animatedShapes.item(1).lottie.kill()
        this.animatedShapes.item(3).lottie.kill()
        this.imageScrollBand.kill()
        window.removeEventListener('resize', this.resizeAction)
    }

    loop() {
        const y = Math.max(this.scroll.data.y - this.journey.data.y, 0)
        const max = this.journey.data.height*2/3
        if (y<=max) {
            this.journey.style.transform = `translateY(${y}px)`
        } else {
            this.journey.style.transform = `translateY(${max}px) translateY(${-.75*(y-max)}px)`
        }
        const p = Math.min(1, y/max)
        this.journeyContent.style.transform = `translateX(${-p*100}%) translateX(${p*90}vw)`
    }

}