export default class ImageScrollBand {

    constructor(_elem, _maxScreenWidth, _mobile) {
        this.elem = _elem
        this.data = {
            initialX: null,
            posX: 0,
            lastDeltaX: null,
            elemWidth: 0,
            maxScreenWidth: _maxScreenWidth,
            wheelTimeout: null,
        }
        this.handleStart = _e => this.touchstart(_e)
        this.handleMove = _e => this.touchmove(_e)
        this.handleEnd = _e => this.touchend(_e)
        this.elem.addEventListener('mousedown', this.handleStart)
        this.elem.addEventListener('mousemove', this.handleMove)
        this.elem.addEventListener('mouseup', this.handleEnd)
        if (_mobile) {
            this.elem.addEventListener('touchstart', this.handleStart)
            this.elem.addEventListener('touchmove', this.handleMove)
            this.elem.addEventListener('touchend', this.handleEnd)
            this.elem.addEventListener('touchcancel', this.handleEnd)
        }
        this.handleWheel = _e => this.wheel(_e)
        // this.elem.addEventListener('wheel', this.handleWheel)
        this.articles = this.elem.querySelectorAll('a.article')
        this.resizeAction = _ => {
            this.data.elemWidth = this.elem.offsetWidth - this.elem.parentElement.offsetWidth + 48
            if (this.articles.length > 0) {
                this.data.scrollableZoneWidth = this.elem.parentElement.offsetWidth
                const marginLeft = parseInt((this.articles[0].currentStyle || window.getComputedStyle(this.articles[0])).marginRight);
                this.articleWidth = this.articles[0].offsetWidth + marginLeft
            }
        }
        this.arrows = {
            left: document.querySelector('#js-articles-arrow-left'),
            right: document.querySelector('#js-articles-arrow-right')
        }
        this.resizeAction()
        if (this.arrows.left && this.arrows.right) {
            this.handleArrowLeft = _e => this.arrowClick(_e, "left")
            this.handleArrowRight = _e => this.arrowClick(_e, "right")
            this.arrows.left.addEventListener('click', this.handleArrowLeft)
            this.arrows.right.addEventListener('click', this.handleArrowRight)
            this.updateArrowsDisplay()
        }
        window.addEventListener('resize', this.resizeAction)
    }

    kill() {
        this.elem.removeEventListener('touchstart', this.handleStart)
        this.elem.removeEventListener('touchmove', this.handleMove)
        this.elem.removeEventListener('touchend', this.handleEnd)
        this.elem.removeEventListener('touchcancel', this.handleEnd)
        this.elem.removeEventListener('mousedown', this.handleStart)
        this.elem.removeEventListener('mousemove', this.handleMove)
        this.elem.removeEventListener('mouseup', this.handleEnd)
        this.elem.removeEventListener('wheel', this.handleWheel)
        if (this.arrows.left && this.arrows.right) {
            this.arrows.left.removeEventListener('click', this.handleArrowLeft)
            this.arrows.right.removeEventListener('click', this.handleArrowRight)
        }
    }

    arrowClick(_e, _side) {
        if (this.articles.length == 0) return
        _e.preventDefault()
        const i = this.data.posX / this.articleWidth
        const nextI = Math.max(Math.min(Math.round(i) + (_side == 'left' ? -1 : 1), this.articles.length - 1), 0)
        this.data.posX = Math.min(this.data.elemWidth, nextI * this.articleWidth)
        this.elem.classList.add('animated')
        this.elem.style.transform = `translateX(-${this.getPositionX()}px)`
        this.updateArrowsDisplay()
        setTimeout(() => {
            this.elem.classList.remove('animated')
        }, 300)
    }

    updateArrowsDisplay() {
        if (!this.arrows.left || !this.arrows.right) return
        if (this.data.posX == 0) {
            this.arrows.left.classList.add('invisible')
        } else {
            this.arrows.left.classList.remove('invisible')
        }
        if (this.data.posX >= this.data.elemWidth) {
            this.arrows.right.classList.add('invisible')
        } else {
            this.arrows.right.classList.remove('invisible')
        }
        this.articles.forEach( (_article, _i) => {
            const pos = this.articleWidth * _i
            const isBefore = pos + this.articleWidth * .5 < this.data.posX
            const isAfter = this.data.scrollableZoneWidth < pos + this.articleWidth * .5 - this.data.posX
            if (isBefore || isAfter) {
                _article.classList.add('low')
            } else {
                _article.classList.remove('low')
            }
        })
    }

    getPositionX() {
        return Math.min(this.data.elemWidth, Math.max(0, this.data.posX + this.data.lastDeltaX))
    }

    wheel(_e) {
        if (this.data.maxScreenWidth && window.device.screen.width > this.data.maxScreenWidth) { return }
        if (!this.data.lastDeltaX) {
            this.data.initialX = 1
            this.data.lastDeltaX = _e.deltaX
        } else {
            this.data.lastDeltaX += _e.deltaX
        }
        this.elem.style.transform = `translateX(-${this.getPositionX()}px)`
        this.updateArrowsDisplay()
        this.elem.classList.add('locked')
        clearTimeout(this.data.wheelTimeout)
        this.data.wheelTimeout = setTimeout(() => {
            // console.log('===> wheel timeout')
            this.touchend(null)
        }, 200);
    }

    touchstart(_e) {
        if (this.data.maxScreenWidth && window.device.screen.width > this.data.maxScreenWidth) { return }
        // console.log('=== start ===')
        _e.preventDefault()
        _e.stopPropagation()
        this.data.initialX = _e.touches ? _e.touches[0].clientX : _e.screenX
    }

    touchmove(_e) {
        if (!this.data.initialX) { return }
        // console.log('=== move ===')
        _e.preventDefault()
        _e.stopPropagation()
        this.data.lastDeltaX = this.data.initialX -(_e.touches ? _e.touches[0].clientX : _e.screenX)
        this.elem.style.transform = `translateX(-${this.getPositionX()}px)`
        this.updateArrowsDisplay()
        this.elem.classList.add('locked')
        // console.log(this.elem.style.transform)
    }

    touchend(_e) {
        if (!this.data.initialX) { return }
        if (_e !== null) {
            _e.preventDefault()
            _e.stopPropagation()
        }
        // console.log('=== end ===')
        this.data.posX = this.getPositionX()
        this.data.initialX = null
        this.data.lastDeltaX = null
        this.updateArrowsDisplay()
        clearTimeout(this.data.wheelTimeout)
        this.data.wheelTimeout = null
        this.elem.classList.remove('locked')
    }

}