import LottiePlayer from "../class/LottiePlayer"
import Page from "./Page"

export default class Design extends Page {

    constructor(_scroll, _device) {
        super(_scroll, _device)
        this.main = document.querySelector('#js-design')
        this.works = this.main.querySelectorAll('.workContainer')
        this.filters = this.main.querySelectorAll('#js-filters .tag')
        this.animatedShapes.item(0).lottie = new LottiePlayer('lottie-design-hexagon', 'p3_hexagon', _scroll)
        this.animatedShapes.item(1).lottie = new LottiePlayer('lottie-design-pentagon', 'p3_pentagon', _scroll)
        this.animatedShapes.item(2).lottie = new LottiePlayer('lottie-design-bubbledDiamond', 'p3_bubbled_diamond', _scroll)
        this.resizeAction = _ => {
            this.works.forEach(work => {
                work.path = work.querySelector('.main-path')
                work.data = {
                    y: work.getBoundingClientRect().top + this.scroll.data.y,
                    height: work.offsetHeight
                }
            })
        }
        this.resizeAction()
        window.addEventListener('resize', this.resizeAction)
        this.filterAction = _e => this.filter(_e)
        this.filters.forEach(filter => {
            filter.addEventListener('click', this.filterAction)
        })
    }

    kill() {
        super.kill()
        this.animatedShapes.item(0).lottie.kill()
        this.animatedShapes.item(1).lottie.kill()
        this.animatedShapes.item(2).lottie.kill()
        window.removeEventListener('resize', this.resizeAction)
        this.filters.forEach(filter => {
            filter.removeEventListener('click', this.filterAction)
        })
    }

    filter(_e) {
        _e.preventDefault()
        _e.stopPropagation()
        const tag = _e.target.href.split("/").pop()
        const isUnselecting = this.main.classList.contains(tag)
        this.main.classList.add('filtered', 'hiddenAll')
        this.main.getBoundingClientRect()
        window.scrollTo(0, this.device.screen.height * .5)
        setTimeout(() => {
            this.main.classList = isUnselecting ? '' : 'filtered noneDisplay'
            if (!isUnselecting) {
                this.main.getBoundingClientRect()
                this.main.classList.add(tag)
            }
            this.main.getBoundingClientRect()
            this.resizeAction()
        }, 500)
    }

    loop() {
        this.works.forEach(work => {
            const v = work.data.y - this.scroll.data.y - (this.device.screen.height * 0.8)
            if (v < 0) {
                work.classList.add('visible')
            }
        })
    }

}