import LottiePlayer from "../class/LottiePlayer"
import Page from "./Page"

export default class Vision extends Page {

    constructor(_scroll, _device) {
        super(_scroll, _device)
        this.animatedShapes.item(0).lottie = new LottiePlayer('lottie-vision-hexagon', 'p2_circle', _scroll)
        this.pictureShapes = document.querySelectorAll('.js-pictureShape')
        this.resizeAction = _ => {
            this.pictureShapes.forEach(shape => {
                shape.path = shape.querySelector('.main-path')
                const rect = shape.parentElement.getBoundingClientRect()
                shape.data = { y: rect.top + this.scroll.data.y, height: rect.height }
            })
        }
        this.resizeAction()
        window.addEventListener('resize', this.resizeAction)
    }

    kill() {
        super.kill()
        this.animatedShapes.item(0).lottie.kill()
        window.removeEventListener('resize', this.resizeAction)
    }

    loop() {
        this.pictureShapes.forEach(shape => {
            const d = shape.data.y - this.scroll.data.y
            const f = d / this.device.screen.height
            shape.path.style.transform = `translateY(${20 + f * 40}px) translateX(20px)`
        })
    }

}