import Page from "./Page"

export default class Work extends Page {

    constructor(_scroll, _device) {
        super(_scroll, _device)
        this.endTransitionToWork()
    }

    endTransitionToWork() {
        const overlayImg = document.querySelector('#transition-work-image')
        if (!overlayImg) { return }
        const destImg = document.querySelector('.main-picture')
        const destImgBoundingRect = destImg.getBoundingClientRect()
        const x = destImgBoundingRect.left - parseInt(overlayImg.style.left)
        const y = destImgBoundingRect.top - parseInt(overlayImg.style.top)
        const scaleX = destImg.offsetWidth / overlayImg.offsetWidth
        const scaleY = destImg.offsetHeight / overlayImg.offsetHeight
        overlayImg.style.transform = `translateX(${x}px) translateY(${y}px) scaleX(${scaleX}) scaleY(${scaleY})`
        setTimeout(() => {
            document.documentElement.classList.add('ending-animation-to-work')
            setTimeout(() => {
                document.querySelector('.transi-screen').remove()
                overlayImg.remove()
                document.documentElement.classList.remove('is-animating-to-work', 'ending-animation-to-work')
            }, 300)
        }, 700)
    }

}