export default class Menu {

    constructor(_scroll) {
        this.elem = document.querySelector('header')
        this.pageLinks = this.elem.querySelectorAll('a.simple')
        this.contact = document.querySelector('#js-always-on-contact')
        this.data = {
            isHidden: false
        }
        this.scroll = _scroll
        this.loop()
    }

    toggle() {
        this.elem.classList.toggle('open')
    }

    close() {
        this.elem.classList.remove('open')
        this.elem.classList.remove('hidden')
    }

    nowFocusOn(_id) {
        this.pageLinks.forEach(_link => {
            if (_link.getAttribute('href') == `/${_id}`) {
                _link.classList.add('current')
            } else {
                _link.classList.remove('current')
            }
        })
    }

    loop() {
        requestAnimationFrame(_ => this.loop())
        const progToEnd = (this.scroll.data.totalHeight - this.scroll.data.y)
        if (progToEnd < window.device.screen.height) {
            this.contact.classList.add('cameleon')
            if (progToEnd < window.device.screen.height/2) {
                this.contact.classList.add('hidden')
            } else {
                this.contact.classList.remove('hidden')
            }
        } else {
            this.contact.classList.remove('cameleon', 'hidden')
        }
        // Menu display
        if (this.scroll.data.lastDelta > 0 && !this.data.isHidden) {
            this.elem.classList.add('hidden')
            this.data.isHidden = true
        }
        else if (this.scroll.data.y == 0 || (this.scroll.data.lastDelta < 0 && this.data.isHidden)) {
            this.elem.classList.remove('hidden')
            this.data.isHidden = false
        }
    }

}