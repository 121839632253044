import Lottie from "lottie-web";

export default class LottiePlayer {

    constructor(divID, filename, _scroll) {
        this.scroll = _scroll
        this.played = false
        this.container = document.querySelector(`#${divID}`)
        this.autoplay = this.container.getAttribute('data-autoplay') != null ? true : false
        this.autoplayDelay = this.container.getAttribute('data-autoplay') ?? 1500
        this.animation = Lottie.loadAnimation({
            container: this.container,
            renderer: 'svg',
            autoplay: false,
            loop: false,
            path: `/assets/lottie/${filename}.json`
        })
        this.resizing = _ => {
            const rect = this.container.getBoundingClientRect()
            this.y = rect.top + this.scroll.data.y
            this.height = rect.height
        }
        this.resizing()
        window.addEventListener('resize', this.resizing)
    }

    kill() {
        this.isAlive = false
        window.removeEventListener('resize', this.resizing)
    }

    update(f) {
        if (this.played && this.autoplay) return
        if (f > 1) return
        if (this.autoplay) {
            this.played = true
            setTimeout(() => {
                this.animation.play()
            }, this.autoplayDelay)
        } else {
            this.animation.play()
            let frame = Math.min(this.animation.totalFrames,
                Math.max(0,
                    2 * (1 - f) * this.animation.totalFrames
                )
            )
            this.animation.goToAndStop(frame, true)
        }
    }

}