import Page from "./Page"

export default class Article extends Page {

    constructor(_scroll, _device) {
        super(_scroll, _device)
        this.endTransitionToArticle()
    }

    endTransitionToArticle() {
        const overlayArticle = document.querySelector('#transition-article-preview')
        if (!overlayArticle) { return }
        setTimeout(() => {
            overlayArticle.style.transform = overlayArticle.style.transform + ' translateY(-100vh)'
            document.documentElement.classList.add('ending-animation-to-article')
            setTimeout(() => {
                document.querySelector('.transi-screen').remove()
                overlayArticle.remove()
                document.documentElement.classList.remove('is-animating-to-article', 'ending-animation-to-article')
            }, 300)
        }, 700)
    }

}