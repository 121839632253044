import { checkEmail } from "../Helpers"
import Page from "./Page"

export default class Contact extends Page {

    constructor(_scroll, _device) {
        super(_scroll, _device)
        this.data = {
            type: null,
            step: 0
        }
        this.main = document.querySelector('#js-mainContact')
        // Form
        this.form = this.main.querySelector('#js-contactForm')
        this.sendFormAction = _e => this.sendForm(_e)
        this.form.addEventListener('submit', this.sendFormAction)
        // Start contact buttons
        this.startContactButtons = this.main.querySelectorAll('.js-contactType')
        this.startContactAction = _e => this.startContact(_e.target.getAttribute('href'), _e)
        this.startContactButtons.forEach(btn => btn.addEventListener('click', this.startContactAction))
        // Back buttons
        this.backButtons = this.main.querySelectorAll('.backButton')
        this.backAction = _e => this.back(_e)
        this.backButtons.forEach(btn => btn.addEventListener('click', this.backAction))
        // Newsletter
        this.newsletterForms.forEach(form => {
            form.setCustomHandler(_ => {
                this.main.classList.add('newsletterSuccess')
                this.scroll.goTo(0)
            })
        })
        // Auto-redirection
        this.autostart()
    }

    kill() {
        super.kill()
        this.form.removeEventListener('submit', this.sendForm)
        this.startContactButtons.forEach(btn => btn.removeEventListener('click', this.startContactAction))
        this.backButtons.forEach(btn => btn.removeEventListener('click', this.backAction))
    }

    sendForm(_e) {
        _e.preventDefault()
        if (this.data.step == 1) {
            this.goToSecondStep()
        } else {
            this.submitForm()
        }
    }

    goToSecondStep() {
        let data = new FormData(this.form)
        switch (this.data.type) {
            case 'project':
                if (data.get('pro-name').length <= 0) {
                    this.inputBoxes['pro-name'].setErrorMessage('Tell us your name')
                    return
                }
                if (data.get('pro-email').length <= 0 || !checkEmail(data.get('pro-email'))) {
                    this.inputBoxes['pro-email'].setErrorMessage('Tell us your email')
                    return
                }
                this.inputBoxes['pro-context'].startFocus()
                _paq.push(['trackEvent', 'ProjectContactFlow', 'ProjectContactFlow-Step2'])
                break
            case 'team':
                if (data.get('team-name').length <= 0) {
                    this.inputBoxes['team-name'].setErrorMessage('Tell us your name')
                    return
                }
                if (data.get('team-email').length <= 0 || !checkEmail(data.get('team-email'))) {
                    this.inputBoxes['team-email'].setErrorMessage('Tell us your email')
                    return
                }
                _paq.push(['trackEvent', 'TeamContactFlow', 'TeamContactFlow-Step2'])
                this.inputBoxes['team-motivation'].startFocus()
                break
        }
        this.main.classList.add('step2')
        this.data.step = 2
    }

    submitForm() {
        let data = new FormData(this.form)
        data.append('contact-type', this.data.type)
        switch (this.data.type) {
            case "project":
                if (data.get('pro-context').length <= 0) {
                    this.inputBoxes['pro-context'].setErrorMessage('Tell us about your needs')
                    return
                }
                _paq.push(['trackEvent', 'ProjectContactFlow', 'ProjectContactFlow-Step3-send'])
                break
            case "team":
                _paq.push(['trackEvent', 'TeamContactFlow', 'TeamContactFlow-Step3-send'])
                break // No check
        }
        fetch('/php/actions/contact.php', { method: 'POST', body: data })
            // .then(res => res.text())
            // .then(text => console.log(text))
            .then(res => res.json())
            .then(json => {
                if (json.success) {
                    this.main.classList.add('success')
                    this.scroll.goTo(0)
                } else {
                    console.warn('Fail : ', json)
                    window.alert(`Une erreur est survenue : ${json.error}. Merci, de nous contactez par mail à hello@meaningful.fr.`)
                }
            })
            .catch(err => {
                console.warn('Catch : ',err)
                window.alert(`Une erreur est survenue : ${err}. Merci, de nous contactez par mail à hello@meaningful.fr.`)
            })
    }

    autostart() {
        const url = window.location.href.split('/')
        if (url.length >= 5) {
            this.startContact(window.location.href, null)
        }
    }

    startContact(_url, _e) {
        if (_e) _e.preventDefault()
        const dest = _url.split('/').pop()
        this.main.classList = dest+" step1"
        this.data.type = dest
        this.data.step = 1
        switch (this.data.type) {
            case 'project':
                _paq.push(['trackEvent', 'ProjectContactFlow', 'ProjectContactFlow-Step1'])
                this.inputBoxes['pro-name'].startFocus()
                break
            case 'team':
                _paq.push(['trackEvent', 'TeamContactFlow', 'TeamContactFlow-Step1'])
                this.inputBoxes['team-name'].startFocus()
                break
        }
    }

    back(_e) {
        _e.preventDefault()
        this.main.classList.remove(`step${this.data.step}`)
        if (this.data.step == 1) {
            this.main.classList.remove('team', 'project')
            this.data.type = null
        }
        this.data.step = Math.max(0, this.data.step - 1) 
    }

}