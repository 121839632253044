import InputBox from "./InputBox";

export default class FileInputBox extends InputBox {

    constructor(_elem) {
        super(_elem)
        this.filesDescription = this.elem.querySelector('.filesDescription')
        this.changeAction = _e => this.changeFile(_e)
        this.input.addEventListener('change', this.changeAction)
        this.clickAction = _e => this.searchFile(_e)
        this.elem.addEventListener('click', this.clickAction)
    }

    searchFile(_e) {
        this.input.click()
    }

    changeFile(_e) {
        const fileNames = [...this.input.files].map(file => file.name).join(', ')
        if (this.input.files.length > 0) {
            this.elem.classList.add('focus')
            const s = this.input.files.length > 1 ? 's' : ''
            this.filesDescription.innerText = `${this.input.files.length} file${s} (${fileNames})`
        } else {
            this.elem.classList.remove('focus')
            this.filesDescription.innerText = ""
        }
    }

}