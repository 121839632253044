export default class InputBox {

    constructor(_elem) {
        this.elem = _elem
        this.input = this.elem.querySelector('input'),
        this.errorLabel = this.elem.querySelector('.error')
        this.focusAction = _e => this.focus(_e)
        this.input.addEventListener('focus', this.focusAction)
        this.blurAction = _e => this.blur(_e)
        this.input.addEventListener('blur', this.blurAction)
        this.inputAction = _e => this.newInput(_e)
        this.input.addEventListener('input', this.inputAction)
        if (this.input.value != '') {
            this.focus(null)
        }
    }

    setErrorMessage(_msg) {
        this.errorLabel.innerText = _msg
        this.elem.classList.add('error')
        this.startFocus()
    }

    startFocus() {
        this.input.focus()
    }

    focus(_e) {
        this.elem.classList.add('focus')
    }

    blur(_e) {
        if (this.input.value.length == 0) {
            this.elem.classList.remove('focus')
        }
    }

    newInput(_e) {
        this.elem.classList.remove('error')
    }

}